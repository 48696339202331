
/* for mobile*/
function windowLoad(func) {
    bindEvent(win, 'load', func);
}
function hasClass(elem, className) {
    return elem.className && (new RegExp(className, 'g')).test(elem.className);
}
function toggleClass(elem, className) {
    if (hasClass(elem, className)) {
        elem.className = elem.className.replace((new RegExp(className, 'g')), "");
    } else {
        elem.className += " " + className;
    }
}
function addClass(elem, className) {
    if (!hasClass(elem, className)) {
        elem.className += " " + className;
    }
}
function removeClass(elem, className) {
    if (hasClass(elem, className)) {
        elem.className = elem.className.replace((new RegExp(className, 'g')), "");
    }
}
function setClass(elem, className) {
    elem.className = className;
}
function preventDefault(e) {
    if (e) { if (e.preventDefault) { e.preventDefault(); } }
}
function stopPropagation(e) {
    if (e) { if (e.stopPropagation) { e.stopPropagation(); } }
}
function bindEvent(elem, name, func) {
    //      if ($) {
    //          if ($.fn.on) {
    //              $(elem).on(name, func);
    //          } else if ($.fn.bind) {
    //              $(elem).bind(name, func);
    //          }
    //} else
    if (elem.addEventListener) {
        elem.addEventListener(name, func);
    } else if (elem.attachEvent) {
        elem.attachEvent('on' + name, func);
    } else {
        elem['on' + name] = function (e) { func(e); }
    }
}

var menuBar = document.getElementById("menuBarLink");
var menuLinksSignIn = document.getElementById("menuLinksMobileSignedIn");
var menuLinksSignOut = document.getElementById("menuLinksMobileSignedOut");
var addClassName = 'show-menu';
try {
    legal_pages1 = JSON.parse(legal_pages1);
} catch (e) {
    legal_pages1 = {}; // Fallback to an empty object if parsing fails
}
if (menuBar) {
    bindEvent(menuBar, 'click', function (e) {
        preventDefault(e);
        stopPropagation(e);
        if (menuLinksSignIn && legal_pages1.is_sign == 'Y') {
            addClass(menuLinksSignIn, "show-menu");
        }
        if (menuLinksSignOut) {
            addClass(menuLinksSignOut, "show-menu");
        }
        document.body.classList.add("no-scroll");
    });
    var closeMobileMenuEvent = function (e) {
        preventDefault(e);
        stopPropagation(e);
        if (menuLinksSignIn && legal_pages1.is_sign == 'Y') {
            setClass(menuLinksSignIn, "menu-links-mobile");
        }
        if (menuLinksSignOut && legal_pages1.is_sign != 'Y') {
            setClass(menuLinksSignOut, "menu-links-mobile");
        }
        document.body.classList.remove("no-scroll");
    };
    var closeIcon = document.getElementById("closeIcon");
    var closeIconSignOut = document.getElementById("closeIconSignOut");
    bindEvent(closeIcon, 'click', closeMobileMenuEvent);
    if (closeIconSignOut) {
        bindEvent(closeIconSignOut, 'click', closeMobileMenuEvent);
    }
}

/* for Desktop*/
var myAccount = document.getElementById("my-account");
var dropdownMenu = document.getElementById("dropdown-menu");
if (myAccount) {
    bindEvent(myAccount, 'click', function (e) {
        preventDefault(e);
        stopPropagation(e);
        toggleClass(dropdownMenu, "focus-in");
    });
    bindEvent(document, 'click', function (e) {
        removeClass(dropdownMenu, "focus-in");
    });
}

var SignOut = function () {
    // var email = document.getElementById('hdnUserEmail').value;
    createNewCookie("rme", "", -1);
    createNewCookie("jsp", "", -1);
    createNewCookie("wbc", "", -1);
    createNewCookie("wbcl", "", -1);
    createNewCookie("docId", "", -1);
    createNewCookie("clJobId", "", -1);
    createNewCookie("cldocId", "", -1);
    //createNewCookie("sign", "", -1);
    /* RDL.Plugin('analytics').Ready(function () {
         RDL.Analytics.Track('sign out', { 'email': email, LoggedInStatus: 'FALSE' });
         analytics.reset();
     });*/
}

//slick slider new
var prof = document.querySelectorAll(".professional-items").length;

$(document).ready(function () {
    if (prof > 0) {
        $(".professional-items").slick({
            autoplay: false,
            autoplaySpeed: 300,
            arrows: true,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            nextArrow: '<div class="circle-arrow arrow-left"><i class="far fa-arrow-right"></i></div>',
            prevArrow: '<div class="circle-arrow arrow-right"><i class="far fa-arrow-left"></i></div>',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        arrows: false
                    }
                }
            ]
        })
        if ($(window).width() <= 767) {
            $('.tools-items').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                dots: true,
                arrows: false
            });
        }
    }
	var  widthdocument = $(window).width();
            if (widthdocument > 767) {
                var $tablesec = $('.table-content');
                var $tabmain = $('.tab-main');

                $(window).scroll(function () {
					if($tabmain.length < 1)
					{
						return;
					}
                    var Ttop = $tabmain.offset().top;
                    var vTop = $tablesec.offset().top;
                    var scroltop = $(this).scrollTop();
                    if (scroltop > Ttop) {
                        $tablesec.addClass("sticky");
                    }
                    else {
                        $tablesec.removeClass("sticky");
                    }
                });
            }
			  $('.tab-list a').bind('click', function (e) {
				
                e.preventDefault();

                var target = $(this).attr("href"); // Set the target as variable

                // perform animated scrolling by getting top-position of target-element and set it as scroll target
                $('html, body').stop().animate({
                    scrollTop: $(target).offset().top
                }, 600, function () {
                   // location.hash = target; //attach the hash (#jumptarget) to the pageurl
                });

                return false;
            });
        var param = ['read_ck_lp', 'read_ck_sign'];    
    	read_cookie_ajax(encodeURIComponent(param),"");
});
if(document.querySelectorAll('.table-content').length > 0)
{
function resetForm(){
			document.getElementById("accessibilityForm").reset();
		}
        
		document.getElementById("accessibilityForm").onsubmit = function(e) {submit_accessibility_form(e)};

		document.onkeydown = function(evt) {
			evt = evt || window.event;
				if (evt.keyCode == 27) {
					var error_esc = document.querySelectorAll(".error");
					for (i = 0; i < error_esc.length; ++i) {
						error_esc[i].blur();
					} 
			}
		};

		document.getElementById("cus_name").addEventListener("keyup", removeErrorName);
		document.getElementById("cus_email").addEventListener("keyup", removeErrorEmail);
		document.getElementById("cus_comment").addEventListener("keyup", removeErrorComment);

		function removeErrorName() {
			if(document.getElementById('cus_name_err').innerHTML != ''){
			document.getElementById('cus_name_err').innerHTML = '';
			document.getElementById("cus_name_err").removeAttribute("tabIndex");
			}
		}

		function removeErrorEmail() {
			if(document.getElementById('cus_email_err').innerHTML != ''){
			document.getElementById('cus_email_err').innerHTML = '';
			document.getElementById("cus_email_err").removeAttribute("tabIndex");
			}
		}

		function removeErrorComment() {
			if(document.getElementById('cus_comment_err').innerHTML != ''){		
			document.getElementById('cus_comment_err').innerHTML = '';
			document.getElementById("cus_comment_err").removeAttribute("tabIndex");
			}
		}

		function submit_accessibility_form(e) {
			e.preventDefault();
			
			var element = document.getElementById('cus_thanks');
			var btn_element = document.getElementById('submit_btn');
			element.innerHTML = '';
			element.classList.remove('text-success');
			element.classList.remove('text-danger');
			btn_element.classList.add('disabled');
			element.classList.add('ajax-loader');
			
			var cus_name = document.getElementById('cus_name').value;
			var cus_email = document.getElementById('cus_email').value;
			var cus_comment = document.getElementById('cus_comment').value;


			var cus_nameerr_focus   = cus_email_err_focus = cus_comment_err_focus   = 0;

			if (cus_name== "") {
				document.getElementById('cus_name_err').innerHTML = 'Fill the full name field.';
				document.getElementById('cus_name_err').tabIndex = 0;


				cus_nameerr_focus = 1;
			}
			if(cus_email == "") {
				document.getElementById('cus_email_err').innerHTML = 'Fill the email field.';
				document.getElementById('cus_email_err').tabIndex = 0;

				cus_email_err_focus = 2; 
			
			} else{
				// Regular expression for basic email validation
				var regex = /^\S+@\S+\.\S+$/;
				if(regex.test(cus_email) === false) {
					document.getElementById('cus_email_err').innerHTML = 'Fill the email field with correct email format.';
					document.getElementById('cus_email_err').tabIndex = 0;
				    cus_email_err_focus = 2; 
				}
				if(cus_email.length > 150){
					document.getElementById('cus_email_err').innerHTML = 'Fill the email field with max 150 characters.';
					document.getElementById('cus_email_err').tabIndex = 0;
					cus_email_err_focus =2; 
				}
			}

			if (cus_comment== "") {
				document.getElementById('cus_comment_err').innerHTML = 'Fill the comment field.';
				document.getElementById('cus_comment_err').tabIndex = 0;
				cus_comment_err_focus =3; 
			}


			if(cus_nameerr_focus === 1 ){document.getElementById("cus_name_err").focus(); 
				btn_element.classList.remove('disabled');
				element.classList.remove('ajax-loader');
				element.classList.add('text-danger');
				return false;
			}
			if(cus_email_err_focus === 2 ){ document.getElementById("cus_email_err").focus(); 			
				btn_element.classList.remove('disabled');
				element.classList.remove('ajax-loader');
				element.classList.add('text-danger');
				return false;
			}
			if(cus_comment_err_focus === 3 ){ document.getElementById("cus_comment_err").focus(); 			
				btn_element.classList.remove('disabled');
				element.classList.remove('ajax-loader');
				element.classList.add('text-danger');
				return false;
			}
			const xhttp = new XMLHttpRequest();
			var data= {
					"Name": cus_name,
					"Email": cus_email,
					"CustomerIssueType": "",
					"IssueDescription": cus_comment,
					"Message": "",
					"PortalCD":"RND"
					} 
			xhttp.open("POST", "/create-zendesk-ticket", true);
			xhttp.setRequestHeader( 'Content-Type', 'application/json; charset=utf-8' );
			xhttp.responseType = 'json';
			xhttp.send(JSON.stringify(data));
			xhttp.onload = function(response) { 
				btn_element.classList.remove('disabled');
				element.classList.remove('ajax-loader');
				
				if (this.status == 200 && this.response.success == true) {
					element.classList.add('text-success');
					element.innerHTML = 'Message successfully sent';
					setTimeout(function(){
						element.classList.remove('text-success');
						element.innerHTML = '';}, 2000);
					resetForm();
				}else{
					element.classList.add('text-danger');
					element.innerHTML = 'Some error occurred. Please try again!';
					setTimeout(function(){
						element.classList.remove('text-danger');
						element.innerHTML = '';}, 2000);
				}
			};
			return false;
		}
}